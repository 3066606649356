// Misc.
	$misc: (
		z-index-base:		10000
	);

// Duration.
	$duration: (
		menu:				0.35s,
		transition:			0.2s,
		banner:				2.5s
	);

// Size.
	$size: (
		border-radius:		4px,
		element-height:		2.75em,
		element-margin:		2em,
		inner:				65em
	);

// Font.
	$font: (
		family:				('Source Sans Pro', Helvetica, sans-serif),
		family-fixed:		('Courier New', monospace),
		weight:				300,
		weight-bold:		600,
		letter-spacing:		0.025em,
		letter-spacing-alt:	0.25em
	);

// Palette.
	$palette: (
		bg:					#EA8C55,
		bg-alt:				#EA8C55,
		fg:					#ffffff,
		fg-bold:			#ffffff,
		fg-light:			rgba(244,244,255,0.2),
		border:				rgba(212,212,255,0.1),
		border-bg:			rgba(212,212,255,0.035),
		highlight:			#9bf1ff,
		accent1:			#6fc3df,
		accent2:			#8d82c4,
		accent3:			#ec8d81,
		accent4:			#e7b788,
		accent5:			#8ea9e8,
		accent6:			#87c5a4
		// other colours
		// lightest: #EA8C55
		// next: #C75146
		// next: #AD2E24
		// next: #81171B
		// next: #540804
	);